<template>
  <PageWrapper>
    <PagePanel>
      <component :is="currentComponent"></component>
    </PagePanel>
  </PageWrapper>
</template>

<script>
export default {
  computed: {
    currentComponent() {
      return () => this.$config.pageLoader(this.regulator, 'MyProfile');
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/myProfile.scss';
</style>
